import React, { useState } from 'react'

// Components
import { Row, Col } from 'react-bootstrap'
import { TitleText, WelcomeContainer, SubtitleText, UrlContainer, UrlText, CopyButton, Button } from './HomeElements'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa'

const Home = () => {

    // State variables
    const [ artlinkUrl, setArtlinkUrl ] = useState("https://trezde.com/artlinks/artypical")

    return (
        <Row className="justify-content-center min-vh-100" style={{ background: "#FEFEFE" }}>
            <Col xs={11} sm={10}>
                <WelcomeContainer>
                    <TitleText>
                        Welcome Artypical
                    </TitleText>
                    <SubtitleText>
                        This is the URL for you Artlink:
                    </SubtitleText>
                    <UrlContainer>
                        <UrlText>
                            {artlinkUrl}
                        </UrlText>
                        <CopyToClipboard text={artlinkUrl}>
                            <CopyButton>
                                <FaCopy color="inherit" size="inherit"/>
                            </CopyButton>
                        </CopyToClipboard>
                    </UrlContainer>
                    <Button>
                        My portfolio
                    </Button>
                </WelcomeContainer>

            </Col>
        </Row>

    )
}

export default Home
