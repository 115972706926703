import React from 'react'

// Components
import { Container, Row, Col } from 'react-bootstrap'
import Menu from '../../components/Dashboard/Menu'
import Home from '../../components/Dashboard/Home'
import Lowbar from '../../components/Dashboard/Lowbar'
import Portfolio from '../../components/Dashboard/Portfolio'

// Router
import { Route, Switch, useRouteMatch } from 'react-router-dom'

const Dashboard = () => {

    let { path } = useRouteMatch();

    return (
        <Container fluid>
            <Row>
                <Col xs={2} md={3} xl={2} className="d-none d-lg-block position-fixed">
                    <Menu/>
                </Col>
                <Col  lg={{ span: 9, offset: 3 }} xl={{ span: 10, offset: 2 }}>
                    <Switch>
                        <Route exact path={`${path}`} render={() => <Home />} />
                        <Route exact path={`${path}/portfolio`} render={() => <Portfolio/>} />
                        <Route exact path={`${path}/*`} render={() => <Home />} />
                    </Switch>
                </Col>
            </Row>
            <Lowbar/>

        </Container>
    )
}

export default Dashboard
