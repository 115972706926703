import React, { useState } from 'react'

// Components
import { SigninContainer, SuscribeContainer, SuscribeTitle, SuscribeSubtitle, SuscribeInput, SuscribeButton, SuscribeMessage, LoginContainer, LoginTitle, LoginSubtitle } from './SigninElements'
import { Container, Col, Row } from 'react-bootstrap'
import Input from '../../Shared/Input'

// Media
import suscribe_bg from '../../../media/images/login_banner.png'

// Libraries
import emailjs from 'emailjs-com';

const Signin = () => {

    // Styles
    const suscribeBackground = {
        background: `url(${suscribe_bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    }

    // State variables
    const [suscribed, setSuscribed] = useState(false)

    // FUNCTIONS
    // Emailjs
    function suscribeEmail(e) {
        e.preventDefault();
        setSuscribed(true);

        emailjs.sendForm('info@trezde.com', 'template_1x7tq1q', e.target, 'user_0BDdbAIpgeYsZG0ZXfysn')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }


    return (
        <SigninContainer>
            <Container fluid>
                <Row>
                    <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }} >
                        <Row className="justify-content-center min-vh-100 align-items-center" style={suscribeBackground}>
                            <Col xs={10} md={7}>
                                <SuscribeTitle>
                                    Suscribe to get regular updates!
                                </SuscribeTitle>
                                <SuscribeSubtitle>
                                    Enter your email
                                </SuscribeSubtitle>
                                <form onSubmit={suscribeEmail}>
                                    <SuscribeInput type="email" name="email" required />
                                    {
                                        suscribed ? (
                                            <SuscribeMessage>
                                                Awesome! Now you will regularly receive news about us to your email
                                            </SuscribeMessage>
                                        ) : (
                                            <SuscribeButton value="Send" type="submit" />
                                        )
                                    }
                                </form>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 2 }}>
                        <Row className="justify-content-center align-items-center min-vh-100">
                            <Col xs={11} md={7}>
                                <LoginContainer>
                                    <LoginTitle>
                                        Welcome Back!
                                    </LoginTitle>
                                    <LoginSubtitle>
                                        Login your account
                                    </LoginSubtitle>
                                    <form>
                                        <Input name={"email"} type={"email"} label={"Email*"} required={true} />
                                        <Input name={"password"} type={"password"} label={"Pasword*"} required={true} />
                                        <Input type={"submit"} value={"Sign in"} />
                                    </form>
                                </LoginContainer>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </SigninContainer>
    )
}

export default Signin
