import React from 'react'

// Components
import Navbar from '../../components/Shared/Navbar'
import Banner from '../../components/Landing/Banner';
import Info from '../../components/Landing/Info';
import Clients from '../../components/Landing/Clients';
import Services from '../../components/Landing/Services';
import Support from '../../components/Landing/Support';
import Contact from '../../components/Landing/Contact';
import Footer from '../../components/Shared/Footer';

const Landing = () => {
    return (
        <>
            <Navbar navbarStyle={0}/>
            <Banner />
            <Info />
            <Clients />
            <Services />
            <Support />
            <Contact />
            <Footer />
        </>
    )
}

export default Landing
