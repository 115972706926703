import React from 'react'
import {
    InfoContainer,
    InfoTitle,
    InfoSubtitle,
    InfoCard,
    InfoImg,
    InfoCardTitle,
    InfoCardDescription
} from './InfoElements'
import { Container, Row, Col } from 'react-bootstrap'

import img1 from '../../../media/images/doubt.svg'
import img2 from '../../../media/images/satisfaction.svg'
import img3 from '../../../media/images/highlight.svg'



const Info = () => {
    return (
        <InfoContainer id="about">
            <Container>
                <Row className="justify-content-center">
                    <Col xs={11} >
                        <InfoTitle>
                            Let them not only imagine your products, but also test them
                        </InfoTitle>
                        <InfoSubtitle>
                            How will it look on my wall?, will it fit in my living room?, will it look good on me? Help your clients to answer this questions when buying your products online with our affordable virtual testers technology
                        </InfoSubtitle>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={11} sm={6} md={4}>
                        <InfoCard>
                            <InfoImg src={img1} />
                            <InfoCardTitle>
                                Less doubts, more sales
                            </InfoCardTitle>
                            <InfoCardDescription>
                                Allow your customers to test your products from their smartphones on themselves or in their physical spaces and dispel their doubts
                            </InfoCardDescription>
                        </InfoCard>
                    </Col>
                    <Col xs={11} sm={6} md={4}>
                        <InfoCard>
                            <InfoImg src={img2} />
                            <InfoCardTitle>
                                Increase client’s satisfaction
                            </InfoCardTitle>
                            <InfoCardDescription>
                                Create unique online shopping experiences for your customers, and reduce product returns                            </InfoCardDescription>
                        </InfoCard>
                    </Col>
                    <Col xs={11} sm={6} md={4}>
                        <InfoCard>
                            <InfoImg src={img3} />
                            <InfoCardTitle>
                                Highlight your e-commerce
                            </InfoCardTitle>
                            <InfoCardDescription>
                                Differentiate your online store with AR technology and position it alongside the big brands. We make it affordable for you.                            </InfoCardDescription>
                        </InfoCard>

                    </Col>
                </Row>
            </Container>
        </InfoContainer>
    )
}

export default Info
