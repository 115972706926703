import styled from 'styled-components'

export const TextareaContainer = styled.div`
    flex: display;
    flex-direction: column;
    width: 100%;
`

export const TextareaElement = styled.textarea`
    width: 100%;
    background: #FEFEFE;
    font-size: 1rem;
    border: 1.5px solid #CBD3EA;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    color: #263B5E;

    &:focus{
        border: 1.5px solid #624196;
        outline: none;

    }
`
export const LabelElement = styled.label`
    color: #263B5E;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: 1rem;

    @media screen and (max-width: 1200px){
        font-size: 1rem;
    }
    
`