import styled from 'styled-components'

export const InfoContainer= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
`

//---------------------------------------------
// Section titles Text
//---------------------------------------------

export const InfoTitle= styled.div`
    color: #263B5E;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1200px){
        font-size: 1.4rem;
    }

`

export const InfoSubtitle= styled.div`
    color: #6A7695;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 5rem;

    @media screen and (max-width: 1200px){
        font-size: 1rem;
    }
`
//---------------------------------------------

//---------------------------------------------
// Card
//---------------------------------------------

export const InfoCard = styled.div`
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @media screen and (max-width: 1200px){
        padding-left: 0rem;
        padding-right: 0rem;
        
    }

`
//---------------------------------------------

//---------------------------------------------
// Card image
//---------------------------------------------

export const InfoImg = styled.img`
    height: 150px;
    margin-bottom: 2.5rem;

    @media screen and (max-width: 1200px){
        height: 120px;
        margin-bottom: 2rem;
    }

`
//---------------------------------------------

//---------------------------------------------
// Card texts
//---------------------------------------------

export const InfoCardTitle= styled.div`
    color: #263B5E;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1200px){
        font-size: 1.1rem;
    }
`

export const InfoCardDescription= styled.div`
    color: #6A7695;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1200px){
        font-size: 1rem;
    }
`
//---------------------------------------------
