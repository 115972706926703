import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

export const Nav = styled.nav`
    background: ${({ bg, navbarStyle }) => bg ? navbarStyle.backgroundColorScroll : navbarStyle.backgroundColor };
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    transition : background-color  0.3s ease-in-out;
`
export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    max-width: 1100px;
    padding: 0 24px;
`

//---------------------------------------------
// Logo
//---------------------------------------------
export const NavLogo = styled(LinkR)`
    justify-self: flex-start;
    cursor: pointer;
    display: flex;
    align-items : center;
    -webkit-tap-highlight-color: transparent;

`

export const Logo = styled.img`
    max-height: 40%;

    @media screen and (max-width: 768px){
        max-height: 35%;
    }
`
//---------------------------------------------


//---------------------------------------------
// Sandwich for mobile
//---------------------------------------------
export const MobileIcon = styled.div`
    display: none;
    -webkit-tap-highlight-color: transparent;


    @media screen and (max-width: 768px){
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        cursor: pointer;
        color: ${({ navbarStyle }) => navbarStyle.buttonColor}

    }

`
//---------------------------------------------


//---------------------------------------------
// Links
//---------------------------------------------
export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;


    @media screen and (max-width: 768px){
        display: none;
    }
`

export const NavItem = styled.li`
    height: 100%;
`

export const NavLinks = styled(LinkS)`
    color: ${({ navbarStyle }) => navbarStyle.fontColor};
    display: flex;
    align-items: center;
    text-decoration : none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &:hover {
        text-decoration : none;
        color: #CBD3EA;
    }

    &.active{
        border-bottom: 3px solid #01bf71;
    }

`
//---------------------------------------------

//---------------------------------------------
// Button
//---------------------------------------------
export const NavBtn = styled.div`
    display:flex;
    align-items: center;
    height: 100%;
    margin-left: 1.5rem;
`

export const NavBtnLink = styled(LinkR)`
    border-radius : 3px;
    border: 2px solid ${({ navbarStyle }) => navbarStyle.buttonColor};
    background: transparent;
    white-space: nowrap;
    padding: 5px 18px;
    color: ${({ navbarStyle }) => navbarStyle.buttonColor} ;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition : all 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
        transition : all 0.2s ease-in-out;
        background : ${({ navbarStyle }) => navbarStyle.buttonColor};
        color: ${({ navbarStyle }) => navbarStyle.buttonFontColorHover};
        text-decoration : none;

}

`
//---------------------------------------------