import styled from 'styled-components'

export const SupportContainer= styled.div`
    display: flex;
    background: #624196;
    justify-content: center;
    align-items: center;
    width: 100%;
`

//---------------------------------------------
// Logos
//---------------------------------------------
export const LogoCard= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const Logo = styled.img`
    height: 120px;

    @media screen and (max-width: 1200px){
        height: 110px;
    }
`

//---------------------------------------------