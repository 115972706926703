import React from 'react'

// Media

// Components
import Navbar from '../../components/Shared/Navbar'
import Signin from '../../components/Login/Signin'
import Footer from '../../components/Shared/Footer'

const Login = () => {
    return (
        <>
           <Navbar navbarStyle={1}/>
           <Signin/>
           <Footer/>
        </>
    )
}

export default Login
