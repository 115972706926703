import styled from "styled-components";
import {FaTimes} from 'react-icons/fa'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

export const SidebarContainer = styled.aside`
    position : fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #5D3B92, #9072C8);;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${( {isOpen} ) => (isOpen ? '100%' :'0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`

//---------------------------------------------
// Close icon
//---------------------------------------------
export const CloseIcon = styled(FaTimes)`
    color: #fff;
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: transparent;

`
//---------------------------------------------

//---------------------------------------------
// Links
//---------------------------------------------
export const SidebarWrapper = styled.div`
    color: #fff;
`

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns : 1fr;
    grid-template-rows : repeat(5, 80px);

`

export const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    justify-content : center;
    font-size: 1.5rem;
    text-decoration : none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration : none;
    color:#fff;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    
    &:hover{
        color: #CBD3EA;
        transition: 0.25 ease-in-out;
    }
`
//---------------------------------------------

//---------------------------------------------
// Button
//---------------------------------------------

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content : center;
`

export const SidebarRoute = styled(LinkR)`
    border-radius : 3px;
    border: 2px solid white;
    background: transparent;
    white-space: nowrap;
    padding: 7px 22px;
    color: white ;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    transition : all 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
        transition : all 0.2s ease-in-out;
        background : white;
        color: #8D65D7;
        
        
}   
`
//---------------------------------------------