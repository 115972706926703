import React from 'react'

// Components
import Modal from 'react-modal'
import { Container, Row, Col } from 'react-bootstrap'
import { ArtworkUploadContainer, ArtworkImg, UploadButton, ModalTitle, CloseModalButton } from './ArtworkModalElements'
import Input from '../../Shared/Input'
import { FaEdit } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

// Redux
import { useDispatch } from 'react-redux'
import { closeModal } from '../../../redux/actions'

// Media
import default_img from '../../../media/images/default_image.png'

const ArtworkModal = (props) => {

    //Redux
    //------------------------------------------------
    const dispatch = useDispatch()
    //------------------------------------------------  

    // Functions
    //------------------------------------------------
    // Close the modal
    const hideModal = () => {
        dispatch(closeModal())
    }
    //------------------------------------------------

    // Style
    //------------------------------------------------
    // 
    const customModalStyle = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.60)',
            transition: "opacity 2000ms ease-in-out"
        },
        content: {
            inset: "20px",
        }
    }
    //------------------------------------------------


    return (
        <Modal
            isOpen={props.isOpen}
            style={customModalStyle}
            onRequestClose={hideModal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
        >
            <Container className="py-5">
                <Row className="justify-content-center">
                    <Col xs={12} md={6}>
                        <ModalTitle>
                            Add a new artwork
                        </ModalTitle>
                        <ArtworkUploadContainer>
                            <ArtworkImg url={default_img}>
                                <UploadButton>
                                    <FaEdit size="inherit" color="inherit" />
                                </UploadButton>
                            </ArtworkImg>
                        </ArtworkUploadContainer>
                        <form>
                            <Input name={"name"} type={"text"} label={"Name"} required={true} />
                            <Input name={"author"} type={"text"} label={"Author"} required={true} />
                            <Input type={"submit"} value={"Add"} />
                        </form>
                    </Col>
                </Row>
            </Container>
            <CloseModalButton onClick={hideModal}>
                <IoClose size="inherit" color="inherit" />
            </CloseModalButton>
        </Modal>
    )
}

export default ArtworkModal
