import React from 'react'

// Components
import { Container, Row, Col } from 'react-bootstrap'
import { LowbarContainer, LinkElement, Icon } from './LowbarElements'
import { RiLogoutBoxRFill } from 'react-icons/ri'
import { FaHome, FaTh, FaUserAlt, FaStar } from "react-icons/fa";

// Router
import { useRouteMatch } from 'react-router-dom'



const Lowbar = () => {

    // Router
    let { url } = useRouteMatch();
    
    return (
        <LowbarContainer className="d-block d-lg-none">
            <Container fluid>
                <Row>
                    <Col className="px-0">
                        <LinkElement to={`${url}`} >
                            <Icon>
                                <FaHome color="inherit" size="inherit" />
                            </Icon>
                        </LinkElement>
                    </Col>
                    <Col className="px-0">
                        <LinkElement to={`${url}/portfolio`}>
                            <Icon>
                                <FaTh color="inherit" size="inherit" />
                            </Icon>
                        </LinkElement>
                    </Col>
                    <Col className="px-0">
                        <LinkElement to={`${url}/account`}>
                            <Icon>
                                <FaUserAlt color="inherit" size="inherit" />
                            </Icon>
                        </LinkElement>
                    </Col>
                    <Col className="px-0">
                        <LinkElement to={`${url}/suscription`}>
                            <Icon>
                                <FaStar color="inherit" size="inherit" />
                            </Icon>
                        </LinkElement>
                    </Col>
                    <Col className="px-0">
                        <LinkElement to={`/login`}>
                            <Icon>
                                <RiLogoutBoxRFill color="inherit" size="inherit" />
                            </Icon>
                        </LinkElement>
                    </Col>
                </Row>
            </Container>
        </LowbarContainer>
    )
}

export default Lowbar
