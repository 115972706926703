import styled from "styled-components";

export const SigninContainer= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FEFEFE;
`

export const LoginContainer = styled.div`
    padding: 3rem;
    background: white;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.25);
`

//---------------------------------------------
// Suscribe texts
//---------------------------------------------

export const SuscribeTitle= styled.div`
    color: white;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 0.5rem;

    @media screen and (max-width: 1200px){
        font-size: 1.1rem;
    }
`

export const SuscribeSubtitle= styled.div`
    color: white;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1200px){
        font-size: 1rem;
    }
`

export const SuscribeMessage= styled.div`
    color: white;
    font-size: 1rem;

    @media screen and (max-width: 1200px){
        font-size: 0.9rem;
    }
`

//---------------------------------------------

//---------------------------------------------
// Suscribe inputs
//---------------------------------------------

export const SuscribeInput = styled.input`
    width: 100%;
    background: rgba(254, 254, 254, 0.5);    
    font-size: 1rem;
    border: 1.5px solid #CBD3EA;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    color: white;

    &:focus{
        background: rgba(254, 254, 254, 0.4); 
        outline: none;

    }
`



export const SuscribeButton = styled.input`
    background: transparent;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 3px;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #FFFFFF;
    border: 1px solid white;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition : all 0.2s ease-in-out;


    @media screen and (max-width: 1200px){
        font-size: 1rem;
    }

    &:hover{
        transition : all 0.2s ease-in-out;
        background: white;
        color: #8657DD
        
    }
`

//---------------------------------------------

//---------------------------------------------
// Login texts
//---------------------------------------------

export const LoginTitle= styled.div`
    color: #263B5E;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;

    @media screen and (max-width: 1200px){
        font-size: 1.1rem;
    }
`

export const LoginSubtitle= styled.div`
    color: #263B5E;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1200px){
        font-size: 1rem;
    }
`

//---------------------------------------------