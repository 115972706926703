import styled from 'styled-components'

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`

//---------------------------------------------
// Input
//---------------------------------------------

export const InputElement = styled.input`
    width: 100%;
    background: rgba(254, 254, 254, 0.5);    
    font-size: 1rem;
    border: 1.5px solid #CBD3EA;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    color: #263B5E;

    &:focus{
        border: 1.5px solid #624196;
        outline: none;

    }
`
//---------------------------------------------

//---------------------------------------------
// Label
//---------------------------------------------
export const LabelElement = styled.label`
    color: #263B5E;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: 1rem;

    @media screen and (max-width: 1200px){
        font-size: 1rem;
    }
    
`
//---------------------------------------------

//---------------------------------------------
// Input button
//---------------------------------------------

export const InputButton = styled.input`
    background: #8657DD;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 3px;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #FFFFFF;
    width: fit-content;
    border: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out;


    @media screen and (max-width: 1200px){
        font-size: 1rem;
    }

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #624196;
    }
`

//---------------------------------------------
