import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'


export const WelcomeContainer = styled.div`
    display: flex;
    padding: 2rem;
    background: white;
    flex-direction: column;
    margin-top: 2rem;
    box-shadow: 0px 0px 19px -3px #00000040;
`


//---------------------------------------------
// Texts
//---------------------------------------------

export const TitleText = styled.div`
    color: #263B5E;
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 1rem;

    @media screen and (max-width: 1200px){
        font-size: 1.7rem;
    }
`

export const SubtitleText = styled.div`
    color: #263B5E;
    font-size: 1.1rem;
    font-weight: 600;
    padding-bottom: 1rem;

    @media screen and (max-width: 1200px){
        font-size: 1rem;
    }
`

//---------------------------------------------

//---------------------------------------------
// URL
//---------------------------------------------
export const UrlContainer = styled.div`
    display: flex;
    background: #F5F5F5;
    flex-direction: row;
    border-radius: 3px;
    padding: 0.5rem;
    width: fit-content;
    max-width: 100%;
    align-items: center;
    overflow-x: scroll;

    &::-webkit-scrollbar {
    height: 0px;
    background: transparent;
}
`

export const UrlText = styled.div`
    color: #263B5E;
    font-size: 1rem;
    font-weight: 500;
    padding-right: 1rem;

    @media screen and (max-width: 1200px){
        font-size: 0.9rem;
    }
` 

export const CopyButton = styled.div`
    display: flex;
    height: 15px;
    width: 15px;
    min-height: 15px;
    min-width: 15px;
    color: #C4C4C4;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    justify-content: center;
    align-items: center;

    &:hover{
        color: #C0B2D4;
        transition: all 0.2s ease-in-out;
    }
`

//---------------------------------------------

//---------------------------------------------
// Button
//---------------------------------------------
export const Button = styled.button`
    display: flex;
    justify-content: center;
    width: fit-content;
    background: #8657DD;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 3px;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #FFFFFF;
    margin-top: 2rem;
    cursor: pointer;
    border: none;
    transition : all 0.2s ease-in-out;

    @media screen and (max-width: 1200px){
        font-size: 0.9rem;
    }

    &:hover{
        transition : all 0.2s ease-in-out;
        background: #624196;
        text-decoration: none;
        color: white
        
    }
`
//---------------------------------------------