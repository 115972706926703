import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'


//---------------------------------------------
// Logo
//---------------------------------------------

export const LogoContainer = styled.div`
    display: flex;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
`

export const Logo = styled.img`
    width: 80%;
`   
//---------------------------------------------

//---------------------------------------------
// Links
//---------------------------------------------

export const LinksContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
`

export const LinkElement = styled(LinkR)`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: center;
    cursor: pointer;
    background: white;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #FEFEFE;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
    } 
`

export const LinkText = styled.div`
    color: #263B5E;
    font-size: 0.9rem;
    font-weight: 700;
    padding-left: 1rem;

`

//---------------------------------------------

//---------------------------------------------
// LogoutButton
//---------------------------------------------

export const LogoutButton = styled(LinkR)`
    display: flex;
    justify-content: center;
    width: 100%;
    background: #8657DD;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 3px;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #FFFFFF;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition : all 0.2s ease-in-out;
    position: absolute;
    bottom: 1rem;
    left: 0px;

    @media screen and (max-width: 1200px){
        font-size: 0.9rem;
    }

    &:hover{
        transition : all 0.2s ease-in-out;
        background: #624196;
        text-decoration: none;
        color: white
        
    }
`
//---------------------------------------------