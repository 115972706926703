import React, { useState } from 'react'

// Components
import { ContactContainer, ContactTitle, ContactSubtitle, SubmitedText } from './ContactElements'
import Input from '../../Shared/Input'
import Textarea from '../../Shared/Textarea'
import { Container, Row, Col } from 'react-bootstrap'

// Libraries
import emailjs from 'emailjs-com';


const Contact = () => {

    const [submited, setSubmited] = useState(false)

    // Emailjs
    function sendEmail(e) {
        e.preventDefault();
        setSubmited(true);

        emailjs.sendForm('info@trezde.com', 'template_mx1vgu9', e.target, 'user_0BDdbAIpgeYsZG0ZXfysn')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <ContactContainer id="contact">
            <Container>
                <Row className='justify-content-center'>
                    <Col xs={11} >
                        <ContactTitle>
                            Get in touch with Trezde
                        </ContactTitle>
                        <ContactSubtitle>
                            Interested in our technology? Fill the following form and we will contact you as soon as posible
                        </ContactSubtitle>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col xs={11} md={10}>
                        <form onSubmit={sendEmail}>
                            <Row className='justify-content-center'>
                                <Col xs={12} md={6}>
                                    <Input name={"name"} type={"text"} label={"First name*"} required={true} />
                                </Col>
                                <Col xs={12} md={6}>
                                    <Input name={"lastname"} type={"text"} label={"Last name*"} required={true} />
                                </Col>
                                <Col xs={12} md={6}>
                                    <Input name={"email"} type={"email"} label={"Email*"} required={true} />
                                </Col>
                                <Col xs={12} md={6}>
                                    <Input name={"company"} type={"text"} label={"Company name"} />
                                </Col>
                                <Col xs={12}>
                                    <Textarea name={"message"} label={"Message*"} required={true} rows={3} />
                                </Col>
                            </Row>
                            <Row className='justify-content-center'>
                                {
                                    submited ? (
                                        <Col xs={12}>
                                            <SubmitedText>
                                                Thank you for getting in touch!
                                            </SubmitedText>
                                            <SubmitedText>
                                                We appreciate you contacting us. One of our colleagues will get back in touch with you soon! Have a great day!
                                            </SubmitedText>
                                        </Col>
                                    ) : (
                                        <Input type={"submit"} value={"Submit"} />
                                    )
                                }
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </ContactContainer>
    )
}

export default Contact

