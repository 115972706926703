import React from 'react'

// Components
import { SupportContainer, LogoCard, Logo } from './SupportElements'
import { Container, Row, Col } from 'react-bootstrap'

// Media
import emprendedores from '../../../media/images/ees_logo.svg'
import innovate from '../../../media/images/innovate_logo.svg'


const Support = () => {
    return (
        <SupportContainer>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={6} sm={3}>
                        <LogoCard>
                            <Logo src={emprendedores}/>
                        </LogoCard>
                    </Col>
                    <Col xs={6} sm={3}>
                    <LogoCard>
                            <Logo src={innovate}/>
                        </LogoCard>
                    </Col>
                </Row>
            </Container>
        </SupportContainer>
    )
}

export default Support
