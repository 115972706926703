import styled from 'styled-components'

export const ClientsContainer = styled.div`
    display: flex;
    width: 100%;
    background: linear-gradient(rgba(98,65,150,.66), rgba(150,122,194,.66)), url(${({image}) =>  image});
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

//---------------------------------------------
// Section titles Text
//---------------------------------------------

export const ClientsTitle= styled.div`
    color: white;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1200px){
        font-size: 1.5rem;
    }

`

export const ClientsSubtitle= styled.div`
    color: white;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 5rem;

    @media screen and (max-width: 1200px){
        font-size: 1rem;
        margin-bottom: 3rem;

    }
`
//---------------------------------------------

//---------------------------------------------
// Logos
//---------------------------------------------

export const LogoCard = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`

export const LogoImg = styled.img`
    height: 150px;

    @media screen and (max-width: 1200px){
        height: 120px;
    }

`
//---------------------------------------------