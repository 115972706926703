import React, { useState } from 'react'

// Components
import { FaBars } from 'react-icons/fa'
import { Nav, NavbarContainer, NavLogo, Logo, MobileIcon, NavMenu, NavLinks, NavItem, NavBtn, NavBtnLink } from './NavbarElements'
import Sidebar from '../Sidebar';

// Media
import logo_white from '../../../media/images/logo_white.svg'
import logo_purple from '../../../media/images/logo_purple.svg'

// Redux
import { useDispatch } from 'react-redux'
import { toggle_sidebar } from '../../../redux/actions'


const Navbar = (props) => {

    //-----------------------------------------------------
    // State Variables
    //-----------------------------------------------------
    // navbarBg (used to change background color on scroll)
    const [navbarBg, setNavbarBg] = useState(false)
    //-----------------------------------------------------

    //-----------------------------------------------------
    // Other Variables
    //-----------------------------------------------------
    // navbarStyles (0: background transparent and purple when scrolling, 1: background white and white when scrolling)
    const navbarStyles = [
        {
            "backgroundColor": "transparent",
            "backgroundColorScroll": "#624196",
            "fontColor": "#FFFFFF",
            "buttonColor": "#FFFFFF",
            "buttonFontColorHover": "#624196",

        },
        {
            "backgroundColor": "#FFFFFF",
            "backgroundColorScroll": "#FFFFFF",
            "fontColor": "#263B5E",
            "buttonColor": "#624196",
            "buttonFontColorHover": "#FFFFFF",

        }
    ]
    //-----------------------------------------------------

    //Redux
    const dispatch = useDispatch()

    // Functions
    const toggle = () => {
        dispatch(toggle_sidebar())
    }

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbarBg(true);
        } else {
            setNavbarBg(false);
        }
    }

    window.addEventListener('scroll', changeBackground);

    return (
        <>
            <Sidebar />
            <Nav bg={navbarBg} navbarStyle={navbarStyles[props.navbarStyle]}>
                <NavbarContainer>
                    <NavLogo to='/'>
                        <Logo src={props.navbarStyle == 0 ? logo_white : logo_purple} />
                    </NavLogo>
                    <MobileIcon onClick={toggle} navbarStyle={navbarStyles[props.navbarStyle]}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks
                                navbarStyle={navbarStyles[props.navbarStyle]}
                                to='services'
                                smooth='true'
                                duration='500'
                                offset={-80}
                            >Services</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                                navbarStyle={navbarStyles[props.navbarStyle]}
                                to='clients'
                                smooth='true'
                                duration='500'
                                offset={-80}
                            >Clients</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                                navbarStyle={navbarStyles[props.navbarStyle]}
                                to='about'
                                smooth='true'
                                duration='500'
                                offset={-80}
                            >About us</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                                navbarStyle={navbarStyles[props.navbarStyle]}
                                to='contact'
                                smooth='true'
                                duration='500'
                                offset={-80}
                            >Contact us</NavLinks>
                        </NavItem>
                        {/* <NavBtn>
                            <NavBtnLink navbarStyle={navbarStyles[props.navbarStyle]} to='/login'>LOGIN</NavBtnLink>
                        </NavBtn> */}
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    )
}

export default Navbar
