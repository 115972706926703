import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SideBtnWrap, SidebarLink, SidebarRoute, SidebarWrapper, SidebarMenu } from './SidebarElements'

// Redux
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { toggle_sidebar } from '../../../redux/actions'

const Sidebar = () => {

    const isOpen = useSelector(state => state.sidebar)
    const dispatch = useDispatch()

    const toggle = () => {
        dispatch(toggle_sidebar())
    }

    return (
        <SidebarContainer isOpen={isOpen}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='services' smooth='true' duration='500' offset={-80} onClick={toggle}>Services</SidebarLink>
                    <SidebarLink to='clients' smooth='true' duration='500' offset={-80} onClick={toggle}>Clients</SidebarLink>
                    <SidebarLink to='about' smooth='true' duration='500' offset={-80} onClick={toggle}>About Us</SidebarLink>
                    <SidebarLink to='contact' smooth='true' duration='500' offset={-80} onClick={toggle}>Contact Us</SidebarLink>
                </SidebarMenu>
                {/* <SideBtnWrap>
                    <SidebarRoute to='/login'>LOGIN</SidebarRoute>
                </SideBtnWrap> */}
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar