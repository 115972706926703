import styled from "styled-components";
import { Link as LinkR } from 'react-router-dom'


export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
`

//---------------------------------------------
// Logo
//---------------------------------------------
export const Logo = styled.div`
    width: 130px;
    height: 130px;
    background: url(${({ logo }) => logo});
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 768px){
        width: 110px;
        height: 110px;
    }
`

//---------------------------------------------

//---------------------------------------------
// Text
//---------------------------------------------
export const Title = styled.div`
    color: black;
    font-weight: 600;
    font-size: 1.8rem;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;

    @media screen and (max-width: 768px){
        font-size: 1.3rem;
    }

`
//---------------------------------------------

//---------------------------------------------
// Artworks
//---------------------------------------------
export const ArtworkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 4rem;
`

export const Artwork = styled.img`
    width: 100%;
    max-width: 200px;
`

export const ArtworkAuthor = styled.div`
    color: black;
    font-weight: 600;
    font-size: 1.3rem;
    width: 100%;
    text-align: center;
    margin-top: 1rem;

    @media screen and (max-width: 768px){
        font-size: 1.1rem;
    }
`
export const ArtworkTitle = styled.div`
    color: black;
    font-weight: 400;
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;


    @media screen and (max-width: 768px){
        font-size: 1rem;
    }
`

//---------------------------------------------

//---------------------------------------------
// Buttons
//---------------------------------------------

export const TesterButton = styled(LinkR)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius : 3px;
    border: 2px solid black;
    background: black;
    width: 100%;
    max-width: 200px;
    cursor: pointer;
    transition : all 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
        transition : all 0.2s ease-in-out;
        text-decoration : none;
        background: #3F3E40;
        border: 2px solid #3F3E40;
    }
`
export const Isotipo = styled.img`
    width: 30px;
    height: 30px;

`

export const AddArtworkButton = styled.button`
    display:flex;
    align-items: center;
    width: fit-content;
    border-radius : 3px;
    border: 2px solid black;
    background: white;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-size: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    transition : all 0.2s ease-in-out;
    &:hover {
        transition : all 0.2s ease-in-out;
        text-decoration : none;
        background: black;
        color: white;
    }
`

//---------------------------------------------