import styled from 'styled-components'

export const ContactContainer= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
`

//---------------------------------------------
// Section titles Text
//---------------------------------------------

export const ContactTitle= styled.div`
    color: #263B5E;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1200px){
        font-size: 1.4rem;
    }

`

export const ContactSubtitle= styled.div`
    color: #6A7695;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 5rem;

    @media screen and (max-width: 1200px){
        font-size: 1rem;
        margin-bottom: 2.5rem;

    }
`
//---------------------------------------------

//---------------------------------------------
// Submited message text
//---------------------------------------------
export const SubmitedText= styled.div`
    color: #6A7695;
    font-size: 0.9rem;
    text-align: center;

    @media screen and (max-width: 1200px){
        font-size: 0.9rem;

    }
`
//---------------------------------------------
