import React from 'react'

// Components
import { TextareaContainer, TextareaElement, LabelElement } from './TextareaElements'

const Input = (props) => {
    return (

        <TextareaContainer>
            <LabelElement htmlFor={props.name}>{props.label}</LabelElement>
            {props.required ? (
                <TextareaElement id={props.name} name={props.name} rows={props.rows} required/>
            ) : (
                <TextareaElement id={props.name} name={props.name} rows={props.rows} />
            )
            }
        </TextareaContainer>
    )
}

export default Input