import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const LowbarContainer = styled.div`
    position: fixed;
    min-width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 0;
    background: white;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);

`

//---------------------------------------------
// Links
//---------------------------------------------

export const LinkElement = styled(LinkR)`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #FEFEFE;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
    } 
`

export const Icon = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8657DD;
`

//---------------------------------------------