import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const ArtworkUploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
` 

//---------------------------------------------
// Links
//---------------------------------------------
export const ArtworkImg = styled.div`
    width: 350px;
    height: 350px;
    background: url(${({ url }) => url});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 2rem;

    @media screen and (max-width: 767px){
        width: 220px;
        height: 220px;
    }

    
    @media screen and (min-width: 768px) and (max-width: 1200px){
        width: 250px;
        height: 250px;
    }
`

//---------------------------------------------

//---------------------------------------------
// Button
//---------------------------------------------

export const UploadButton = styled.button`
    display: flex;
    justify-content: center;
    height: 30px;
    width: 30px;
    background: #8657DD;
    color: white;
    cursor: pointer;
    position: absolute;
    transition : all 0.2s ease-in-out;
    bottom: 5px;
    right: 5px;
    border-radius: 3px;
    text-decoration: none;
    padding: 5px;
    border: none;

    &:hover{
        transition : all 0.2s ease-in-out;
        background: #624196;
        text-decoration: none;
        color: white        
    }
`

export const CloseModalButton = styled.button`
    display: flex;
    justify-content: center;
    height: 30px;
    width: 30px;
    background: transparent;
    color: #8657DD;
    cursor: pointer;
    position: absolute;
    transition : all 0.2s ease-in-out;
    top: 5px;
    right: 5px;
    text-decoration: none;
    border: none;

    &:hover{
        transition : all 0.2s ease-in-out;
        color: #624196;
        text-decoration: none;
    }
`
//---------------------------------------------


//---------------------------------------------
// Text
//---------------------------------------------
export const ModalTitle= styled.div`
    color: #263B5E;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 2rem;
    text-align: center;


    @media screen and (max-width: 1200px){
        font-size: 1.5rem;
    }
`
//---------------------------------------------
