import React from 'react'

// Components
import {
    ServicesContainer,
    ServicesTitle,
    ServicesSubitle,
    ServiceTitle,
    ServiceTitleUnderline,
    ServiceVideo,
    ServiceSubtitle,
    ServiceSubtitleItalic,
    ServiceDescription
} from './ServicesElements'
import { Container, Row, Col } from 'react-bootstrap'

// Media
import art_video from '../../../media/videos/artlinksvangogh.mp4'
import art_business_video from '../../../media/videos/kuelga.mp4'
import furniture_video from '../../../media/videos/leia.mp4'
import whairo_video from '../../../media/videos/whairo.mp4'
import glasses_video from '../../../media/videos/glasses.mp4'
import food_video from '../../../media/videos/food.mp4'


const Services = () => {
    return (
        <ServicesContainer id="services">
            <Container>
                <Row className='justify-content-center'>
                    <Col xs={11} >
                        <ServicesTitle>
                            Virtual testers services for different product categories
                        </ServicesTitle>
                        <ServicesSubitle>
                            Our affordable technology can adapt to your needs and economy. We have different virtual testers solutions according to the products you sell in your e-commerce or social networks
                        </ServicesSubitle>
                    </Col>
                </Row>

                {/* Titulo de servicio */}
                <Row className='justify-content-center' id="art">
                    <Col xs={11}>
                        <Row>
                            <Col xs={5} md={4} lg={3}>
                                <ServiceTitle>
                                    Art
                                </ServiceTitle>
                                <ServiceTitleUnderline />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Video izquierda */}
                <Row className='justify-content-center'>
                    <Col xs={{ span: 8, order: 2 }} sm={6} md={{ span: 5, order: 2 }} lg={3}  >
                        <ServiceVideo autoPlay src={art_video} type="video/mp4" loop muted />
                    </Col>
                    <Col xs={{ span: 11, order: 1 }} md={{ span: 6, offset: 1, order: 2 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} className="align-self-center">
                        <ServiceSubtitle>
                            For independent artists: Artlinks
                        </ServiceSubtitle>
                        <ServiceDescription>
                            An Artlink is a personal website for artists, where they can create a complete portfolio of their paintings.
                        </ServiceDescription>
                        <ServiceDescription>
                            Each artwork will have a virtual tester so that online customers can test from their smartphones how it would look on their wall.
                        </ServiceDescription>
                        <ServiceDescription>
                            Share your Artlink on your social networks or Linktree and start getting visitors and buyers.                        </ServiceDescription>
                    </Col>
                </Row>
                {/* Video derecha */}
                <Row className='justify-content-center'>
                    <Col xs={{ span: 8, order: 2 }} sm={6} md={{ span: 5, offset: 1 }} lg={3}  >
                        <ServiceVideo autoPlay src={art_business_video} type="video/mp4" loop muted />
                    </Col>
                    <Col xs={{ span: 11, order: 1 }} md={5} lg={5} xl={7} className="align-self-center">
                        <ServiceSubtitle>
                            For businesses selling art
                        </ServiceSubtitle>
                        <ServiceDescription>
                            Easily integrate our virtual artwork tester system into your website and forget about having to make photo montages for your customers online.
                        </ServiceDescription>
                        <ServiceDescription>
                            Help them test different combinations of paintings, frames and scales, all from the browser of their smartphones, without any additional applications or systems required.
                        </ServiceDescription>

                    </Col>
                </Row>

                {/* Titulo de servicio */}
                <Row className='justify-content-center' id="wearables">
                    <Col xs={11}>
                        <Row>
                            <Col xs={5} md={4} lg={3}>
                                <ServiceTitle>
                                    Wearables
                                </ServiceTitle>
                                <ServiceTitleUnderline />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Video izquierda */}
                <Row className='justify-content-center'>
                    <Col xs={{ span: 8, order: 2 }} sm={6} md={{ span: 5, order: 2 }} lg={3}  >
                        <ServiceVideo autoPlay src={glasses_video} type="video/mp4" loop muted />
                    </Col>
                    <Col xs={{ span: 11, order: 1 }} md={{ span: 6, offset: 1, order: 2 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} className="align-self-center">
                        <ServiceSubtitle>
                            For eyewear stores
                        </ServiceSubtitle>
                        <ServiceDescription>
                            Finding the perfect pair of glasses will be much more easy and practical for your clients with our virtual try-on.                        
                        </ServiceDescription>
                        <ServiceDescription>
                            Help them try on as many glasses as they want and choose their favorite.We will generate the 3D models of the glasses and create testers for each one of them in your e-commerce web or social networks.                        
                        </ServiceDescription>
                    </Col>
                </Row>
                {/* Video derecha */}
                <Row className='justify-content-center'>
                    <Col xs={{ span: 8, order: 2 }} sm={6} md={{ span: 5, offset: 1 }} lg={3}  >
                        <ServiceVideo autoPlay src={whairo_video} type="video/mp4" loop muted />
                    </Col>
                    <Col xs={{ span: 11, order: 1 }} md={5} lg={5} xl={7} className="align-self-center">
                        <ServiceSubtitle>
                            For cap and hat stores
                        </ServiceSubtitle>
                        <ServiceDescription>
                            Show off the hats and caps you sell with our virtual fitting rooms and facilitate your customers' purchasing decision.
                        </ServiceDescription>
                        <ServiceDescription>
                            We will digitize your products in realistic 3D models that will automatically fit your customers' heads.
                        </ServiceDescription>

                    </Col>
                </Row>
            
                {/* Titulo de servicio */}
                <Row className='justify-content-center' id="furniture">
                    <Col xs={11}>
                        <Row>
                            <Col xs={5} md={4} lg={3}>
                                <ServiceTitle>
                                    Furniture
                                </ServiceTitle>
                                <ServiceTitleUnderline />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Video izquierda */}
                <Row className='justify-content-center'>
                    <Col xs={{ span: 8, order: 2 }} sm={6} md={{ span: 5, order: 2 }} lg={3}  >
                        <ServiceVideo autoPlay src={furniture_video} type="video/mp4" loop muted />
                    </Col>
                    <Col xs={{ span: 11, order: 1 }} md={{ span: 6, offset: 1, order: 2 }} lg={{ span: 5, offset: 1 }} xl={{ span: 7, offset: 1 }} className="align-self-center">
                        <ServiceSubtitle>
                            For furniture stores
                        </ServiceSubtitle>
                        <ServiceDescription>
                            Allow your customers to test your store's furniture products from their smartphones.
                            They will be able to see your products in their homes and in real scale, to know if they fit, combine or look good in their rooms.
                        </ServiceDescription>
                        <ServiceDescription>
                            We generate 3D models of your furniture and with them we create virtual testers for your e-commerce website or your social networks.
                        </ServiceDescription>
                    </Col>
                </Row>
            
                {/* Titulo de servicio */}
                <Row className='justify-content-center' id="food">
                    <Col xs={11}>
                        <Row>
                            <Col xs={5} md={4} lg={3}>
                                <ServiceTitle>
                                    Food
                                </ServiceTitle>
                                <ServiceTitleUnderline />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Video derecha */}
                <Row className='justify-content-center'>
                    <Col xs={{ span: 8, order: 2 }} sm={6} md={{ span: 5, offset: 1 }} lg={3}  >
                        <ServiceVideo autoPlay src={food_video} type="video/mp4" loop muted />
                    </Col>
                    <Col xs={{ span: 11, order: 1 }} md={5} lg={5} xl={7} className="align-self-center">
                        <ServiceSubtitle>
                            For restaurants
                        </ServiceSubtitle>
                        <ServiceDescription>
                            Offer your customers the possibility to see your restaurant's menu in 3D, directly from your website or social networks.
                        </ServiceDescription>
                        <ServiceDescription>
                            A picture is worth a thousand words, and a 3D dish much more. Having a virtual menu will allow your customers to have a clear idea of what they are ordering. They will know in advance the size, portions or ingredients, whether they are regular or new diners.
                        </ServiceDescription>

                    </Col>
                </Row>
            
            </Container>
        </ServicesContainer>
    )
}

export default Services
