import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

//Redux 
import store from './redux/store' 
import { Provider } from 'react-redux' 

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

