//-------------------------------------
// sidebar
//-------------------------------------
export const toggle_sidebar = () => { 
    return { 
        type: 'TOGGLE' 
    } 
} 
//-------------------------------------

//-------------------------------------
// modal
//-------------------------------------
export const openModal = () => { 
    return { 
        type: 'OPEN' 
    } 
}

export const closeModal = () => { 
    return { 
        type: 'CLOSE' 
    } 
} 
//-------------------------------------