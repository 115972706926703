import React from 'react'

// Components
import { InputContainer, InputElement, LabelElement, InputButton } from './InputElements'

const Input = (props) => {
    return (

        <InputContainer>
            {
                props.type == "submit" ? (
                    <InputButton type={props.type} value={props.value} />

                ) : (
                    <>
                        <LabelElement htmlFor={props.name}>{props.label}</LabelElement>
                        {props.required ? (
                            <InputElement type={props.type} id={props.name} name={props.name} value={props.value} required />
                        ) : (
                            <InputElement type={props.type} id={props.name} name={props.name} value={props.value} />
                        )
                        }
                    </>
                )
            }

        </InputContainer>
    )
}

export default Input