import React from 'react'

// Components
import { ClientsContainer, ClientsSubtitle, ClientsTitle, LogoCard, LogoImg } from './ClientsElements'
import { Container, Col, Row } from 'react-bootstrap'

// Media
import image from '../../../media/images/clients_section.png'
import kuelga from '../../../media/images/kuelga_logo.svg'
import leia from '../../../media/images/leia_logo.svg'
import whairo from '../../../media/images/whairo_logo.svg'
import mistisushi from '../../../media/images/mistisushi_logo.svg'


const Clients = () => {

    const logos = [kuelga, leia, whairo, mistisushi]


    return (
        <ClientsContainer image={image} id="clients">
            <Container>
                <Row className="justify-content-center">
                    <Col xs={11} >
                        <ClientsTitle>
                            Our clients
                        </ClientsTitle>
                        <ClientsSubtitle>
                            Meet some of the companies and businesses using our technology
                        </ClientsSubtitle>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    {
                        logos.map((logo, index) =>
                            <Col xs={6} sm={3} lg={2} key={index}>
                                <LogoCard>
                                    <LogoImg src={logo} />
                                </LogoCard>
                            </Col>
                        )
                    }

                </Row>
            </Container>
        </ClientsContainer>
    )
}

export default Clients
