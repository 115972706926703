import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'


export const BannerContainer= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background: url(${({image}) =>  image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image 0.5s ease-in-out;

    @media screen and (max-width: 1024px){
        background: linear-gradient(rgba(98,65,150,.3), rgba(98,65,150,.95)), url(${({image}) =>  image});
        background-size: cover;
        background-position: 75% 50%;
        transition: background-image 0.01s ease-in-out;
    }

`
//---------------------------------------------
// Text
//---------------------------------------------
export const BannerText = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    padding: 0 24px;

    @media screen and (max-width: 768px){
        padding: 0 16px;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px){
        padding: 0 48px;;
    }
`

export const BannerTitle = styled.div`
    color: white;
    font-size: 3.5rem;
    font-weight: 500;
    margin-bottom: 2rem;

    @media screen and (max-width: 768px){
        text-align: center;
        font-size: 2.3rem;
    }
`

export const BannerSubtitle = styled.div`
    color: white;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 2rem;

    @media screen and (max-width: 768px){
        text-align: center;
        font-size: 1.2rem;

    }
    
`
//---------------------------------------------

//---------------------------------------------
// Button
//---------------------------------------------

export const BannerBtnWrap = styled.div`
    display: flex;

    @media screen and (max-width: 768px){
        width: 100%;
        justify-content: center;
    }
`

export const BannerBtnRoute = styled(LinkS)`
    border-radius : 3px;
    border: 2px solid white;
    background: transparent;
    white-space: nowrap;
    padding: 7px 18px;
    color: white ;
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
    transition : all 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
        transition : all 0.2s ease-in-out;
        background : white;
        color: #8D65D7;
        text-decoration: none;
}
`
//---------------------------------------------