import React from 'react'

// Components
import { Container, Row, Col } from 'react-bootstrap'
import { LogoContainer, Logo, LinksContainer, LinkElement, LinkText, LogoutButton } from './MenuElements'
import { FaHome, FaTh, FaUserAlt, FaStar } from "react-icons/fa";

// Router
import { useRouteMatch } from 'react-router-dom'

// Media
import logo from '../../../media/images/logo_purple.svg'

const Dashboard = () => {

    
    // Router
    let { url } = useRouteMatch();

    // Styles
    const menuStyle = {
        background: '#FFFFFF',
        marginRight: '2px',
        boxShadow: '5px 0px 14px -5px rgba(0, 0, 0, 0.25)',
    }

    let iconStyle = {
        color: "#8657DD",
        minHeight: "25px",
        minWidth: "25px"

    }

    return (
        
            <Row className="justify-content-center min-vh-100" style={menuStyle}>
                <Col xs={11}>
                    <LogoContainer>
                        <Logo src={logo}/>
                    </LogoContainer>
                    <LinksContainer>
                        <LinkElement to={`${url}`}>
                            <FaHome style={iconStyle}/>
                            <LinkText>
                                Home
                            </LinkText>
                        </LinkElement>
                        <LinkElement to={`${url}/portfolio`}>
                            <FaTh style={iconStyle}/>
                            <LinkText>
                                Portfolio
                            </LinkText>
                        </LinkElement>
                        <LinkElement to={`${url}/account`}>
                            <FaUserAlt style={iconStyle}/>
                            <LinkText>
                                Account
                            </LinkText>
                        </LinkElement>
                        <LinkElement to={`${url}/suscription`}>
                            <FaStar style={iconStyle}/>
                            <LinkText>
                                Suscription
                            </LinkText>
                        </LinkElement>
                    </LinksContainer>
                    <LogoutButton to={"/login"}>
                        Log out
                    </LogoutButton>
                </Col>
            </Row>


    )
}

export default Dashboard
