import React, {useState} from 'react'

// Components
import { Container, Row, Col } from 'react-bootstrap'
import { TitleContainer, Logo, Title, ArtworkContainer, Artwork, ArtworkTitle, ArtworkAuthor, TesterButton, Isotipo, AddArtworkButton } from './PortfolioElements'
import { FaPlus } from "react-icons/fa";
import ArtworkModal from '../ArtworkModal';

// Media
import isotipo from '../../../media/images/isotipo_white.svg'

// Redux
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { openModal } from '../../../redux/actions'

const Portfolio = () => {


    // Other variables
    //------------------------------------------------
    // Modal show flag
    const showModal = useSelector(state => state.showModal)

    // Artworks data for development
    let artworks = [
        {
            "url": "https://trezde.com/static/artlinks/artypical/media/images/night_stalker.jpg",
            "author": "Jessica Wilson",
            "title": "Night Stalker"
        },
        {
            "url": "https://trezde.com/static/artlinks/artypical/media/images/i_ll_follow_you.jpg",
            "author": "Jessica Wilson",
            "title": "I'll follow you"
        },
        {
            "url": "https://trezde.com/static/artlinks/artypical/media/images/dimension_travelling.jpg",
            "author": "Joaquin Robinson",
            "title": "Dimension travelling"
        },
    ]
    //------------------------------------------------

    //Redux
    //------------------------------------------------
    const dispatch = useDispatch()
    //------------------------------------------------  

    // Functions
    //------------------------------------------------
    // Add new artwork
    const addArtwork = () => {
        dispatch(openModal())
    }
    //------------------------------------------------


    return (
        <Row className="justify-content-center min-vh-100" style={{ background: "#FEFEFE" }}>
            <ArtworkModal isOpen={showModal}/>
            <Col xs={10}>
                <Row className="justify-content-center">
                    <TitleContainer>
                        <Logo logo="https://trezde.com/static/artlinks/artypical/media/images/logo.jpg" />
                        <Title>
                            Artypical
                        </Title>
                        <AddArtworkButton onClick={addArtwork}>
                            <FaPlus color="inherit" size="15px" className="mr-2"/>
                            Add new artwork
                        </AddArtworkButton>
                    </TitleContainer>
                </Row>
                <Row className="justify-content-center justify-content-sm-start mb-5">
                    {
                        artworks.map((artwork, index) => (
                            <Col xs={12} sm={6} xl={4} key={index}>
                                <ArtworkContainer>
                                    <Artwork src={artwork.url} />
                                    <ArtworkAuthor>{artwork.author}</ArtworkAuthor>
                                    <ArtworkTitle>{artwork.title}</ArtworkTitle>
                                    <TesterButton to="/">
                                        <Isotipo src={isotipo} />
                                    </TesterButton>
                                </ArtworkContainer>
                            </Col>
                        ))
                    }
                </Row>

            </Col>
        </Row>
    )
}

export default Portfolio
