import './App.css';

//Pages
import Landing from './pages/Landing';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';


// Router
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

//Redux 
import store from './redux/store'
import { Provider } from 'react-redux'

// Others
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Landing />} />
          <Route exact path="/login" render={() => <Login />} />
          <Route path="/dashboard" render={() => <Dashboard />} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
