import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'


export const FooterContainer= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: #263B5E;
`

//---------------------------------------------
// Links
//---------------------------------------------

export const FooterTitle= styled(LinkS)`
    color: white;
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 1rem;
    text-decoration : none;
    cursor: pointer;
    min-width: 100%;
    display:flex;

    @media screen and (max-width: 991px){
        font-size: 0.9rem;
        justify-content: center;
    }

    &:hover {
        text-decoration : none;
        color: #CBD3EA;

    }

`

export const FooterSubtitle= styled(LinkS)`
    color: #BABCBD;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 1rem;
    text-decoration : none;
    cursor: pointer;
    width: 100%;
    display:flex;

    @media screen and (max-width: 991px){
        font-size: 0.9rem;
        justify-content: center;

        
    }

    &:hover {
        text-decoration : none;
        color: #CBD3EA;

    }

`
//---------------------------------------------

//---------------------------------------------
// Logo
//---------------------------------------------
export const FooterLogo = styled(LinkR)`
    justify-content: center;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items : center;
    -webkit-tap-highlight-color: transparent;
    padding-bottom: 3rem;

`
export const Logo = styled.img`
    width: 80%;
`
//---------------------------------------------


//---------------------------------------------
// Social Networks Logos
//---------------------------------------------
export const SocialNetworkLogoContainer = styled(LinkR)`
    cursor: pointer;
    width: 25px;
    margin-right: 15px;
    display: flex;
    -webkit-tap-highlight-color: transparent;

`
export const SocialNetworkLogo = styled.img`
    width: 100%;
`
//---------------------------------------------

//---------------------------------------------
// Text
//---------------------------------------------
export const Copyright= styled.div`
    color: #BABCBD;
    font-size: 0.8rem;
    font-weight: 400;
    padding-bottom: 1rem;
    padding-top: 1rem;
    display:flex;

    @media screen and (max-width: 1200px){
        font-size: 0.9rem;
    }

`
//---------------------------------------------