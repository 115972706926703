import React from 'react'

// Components
import { FooterContainer, FooterLogo, Logo, FooterTitle, FooterSubtitle, SocialNetworkLogoContainer, SocialNetworkLogo, Copyright  } from './FooterElements'
import { Container, Row, Col } from 'react-bootstrap'

// Media
import logo from '../../../media/images/logo_white.svg'
import facebook from '../../../media/images/facebook_logo.svg'
import instagram from '../../../media/images/instagram_logo.svg'
import linkedin from '../../../media/images/linkedin_logo.svg'
import youtube from '../../../media/images/youtube_logo.svg'


const Footer = () => {
    return (
        <FooterContainer>
            <Container>
                <Row className="justify-content-center justify-content-lg-between">
                    <Col xs={5} md={4} lg={2} className="text-start text-lg-center" >
                        <FooterLogo to="/">
                            <Logo src={logo}/>
                        </FooterLogo>
                    </Col>
                    <Col xs={10} lg={6}>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={4}>
                                <FooterTitle
                                    to='banner'
                                    smooth='true'
                                    duration='500'
                                    offset={-80}
                                >
                                    Home
                                </FooterTitle>
                                <FooterSubtitle
                                    to='services'
                                    smooth='true'
                                    duration='500'
                                    offset={-80}
                                >
                                    Services
                                </FooterSubtitle>
                                <FooterSubtitle
                                    to='clients'
                                    smooth='true'
                                    duration='500'
                                    offset={-80}
                                >
                                    Clients
                                </FooterSubtitle>
                                <FooterSubtitle
                                    to='about'
                                    smooth='true'
                                    duration='500'
                                    offset={-80}
                                >
                                    About Us
                                </FooterSubtitle>
                                <FooterSubtitle
                                    to='contact'
                                    smooth='true'
                                    duration='500'
                                    offset={-80}
                                >
                                    Contact Us
                                </FooterSubtitle>
                            </Col>
                            <Col xs={12} lg={4}>
                                <FooterTitle
                                    to='services'
                                    smooth='true'
                                    duration='500'
                                    offset={-80}
                                >
                                    Services
                                </FooterTitle>
                                <FooterSubtitle
                                    to='art'
                                    smooth='true'
                                    duration='500'
                                    offset={-80}
                                >
                                    Art
                                </FooterSubtitle>
                                <FooterSubtitle
                                    to='wearables'
                                    smooth='true'
                                    duration='500'
                                    offset={-80}
                                >
                                    Wearables
                                </FooterSubtitle>
                                <FooterSubtitle
                                    to='furniture'
                                    smooth='true'
                                    duration='500'
                                    offset={-80}
                                >
                                    Furniture
                                </FooterSubtitle>
                                <FooterSubtitle
                                    to='food'
                                    smooth='true'
                                    duration='500'
                                    offset={-80}
                                >
                                    Food
                                </FooterSubtitle>
                            </Col>
                            {/* <Col xs={12} lg={4}>
                                <FooterTitle>
                                    Legal
                                </FooterTitle>
                                <FooterSubtitle>
                                    Terms
                                </FooterSubtitle>
                                <FooterSubtitle>
                                    Privacy
                                </FooterSubtitle>
                            </Col> */}
                        </Row>
                    </Col>
                    <Col xs={10} lg={3   }>
                        <Row className="justify-content-center justify-content-lg-start py-3 py-lg-0">
                            <SocialNetworkLogoContainer to={{ pathname: "https://www.facebook.com/wetrezde" }} target="_blank">
                                <SocialNetworkLogo src={facebook} />
                            </SocialNetworkLogoContainer>
                            <SocialNetworkLogoContainer to={{ pathname: "https://www.instagram.com/trezde_/" }} target="_blank">
                                <SocialNetworkLogo src={instagram} />
                            </SocialNetworkLogoContainer>
                            {/* <SocialNetworkLogoContainer>
                                <SocialNetworkLogo src={linkedin}/>
                            </SocialNetworkLogoContainer>
                            <SocialNetworkLogoContainer>
                                <SocialNetworkLogo src={youtube}/>
                            </SocialNetworkLogoContainer> */}
                        </Row>
                        <Row className="justify-content-center justify-content-lg-start">
                            <Copyright>
                                © 2021 Trezde. All rights reserved.
                            </Copyright>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </FooterContainer>
    )
}

export default Footer
