import styled from 'styled-components'

export const ServicesContainer = styled.div`
    display: flex;
    width: 100%;
    background: #FEFEFE;
    padding-top: 5rem;
    padding-bottom: 5rem;
`

//---------------------------------------------
// Section titles Text
//---------------------------------------------

export const ServicesTitle= styled.div`
    color: #263B5E;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1200px){
        font-size: 1.4rem;
    }

`

export const ServicesSubitle= styled.div`
    color: #6A7695;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 5rem;

    @media screen and (max-width: 1200px){
        font-size: 1rem;
        margin-bottom: 3rem;

    }
`
//---------------------------------------------


//---------------------------------------------
// Service texts
//---------------------------------------------

export const ServiceTitle= styled.div`
    color: #263B5E;
    font-size: 1.6rem;
    font-weight: 600;

    @media screen and (max-width: 1200px){
        font-size: 1.4rem;
    }

`

export const ServiceTitleUnderline = styled.div`
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, #5D3B92, #9073BD);
    margin-bottom: 1.5rem;

`

export const ServiceSubtitle = styled.div`
    color: #263B5E;
    display: flex;
    font-size: 1.5rem;
    font-weight: 600;
    white-space: pre-wrap;
    margin-bottom: 2rem;

    @media screen and (max-width: 1200px){
        font-size: 1.3rem;
    }
`

export const ServiceDescription = styled.div`
    color: #6A7695;
    font-size: 1.2rem;
    margin-bottom: 1rem;


    @media screen and (max-width: 1200px){
        font-size: 1rem;
    }
`

//---------------------------------------------

//---------------------------------------------
// Service Video
//---------------------------------------------

export const ServiceVideo= styled.video`
    color: #263B5E;
    font-size: 1.6rem;
    font-weight: 600;
    border-radius: 13px;
    border: 10px solid #252424;
    width: 100%;
    box-shadow: 0px 8px 12px -1px rgba(0, 0, 0, 0.25);
    margin-top: 2rem;
    margin-bottom: 2rem;

`

//---------------------------------------------