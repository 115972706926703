import React, { useState, useEffect } from 'react'
import { BannerContainer, BannerText, BannerTitle, BannerSubtitle, BannerBtnWrap, BannerBtnRoute } from './BannerElements'

import { Container, Row, Col } from 'react-bootstrap'


import banner1 from '../../../media/images/banner1.jpg'
import banner2 from '../../../media/images/banner2.jpg'
import banner3 from '../../../media/images/banner3.jpg'


const Banner = () => {

    const [counter, setCounter] = useState(0)
    const bannerImgs = [banner1, banner2, banner3]

    useEffect(() => {

        const interval = setInterval(() => {
            setCounter(counter => counter == bannerImgs.length - 1 ? 0 : counter + 1)
        }, 3500);

        return () => {
            clearInterval(interval)
        };

    }, []);

    return (
        <BannerContainer image={bannerImgs[counter]} id="banner">
            <Container>
                <Row>
                    <Col md="7">
                        <BannerTitle>
                            Allow your customers to test your products online
                        </BannerTitle>
                        <BannerSubtitle>
                            Engage costumers with AR virtual testers of your products and enhance your virtual sales
                        </BannerSubtitle>
                        <BannerBtnWrap>
                            <BannerBtnRoute 
                                to='contact'
                                smooth='true'
                                duration='500'
                                offset={-80}
                            >   
                                Get a virtual tester
                            </BannerBtnRoute>
                        </BannerBtnWrap>
                    </Col>
                </Row>
            </Container>
        </BannerContainer>
    )
}

export default Banner
